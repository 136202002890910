import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import UnsubscribedFeedback from "../components/UnsubscribedFeedback"

const UnsubscribedFeedbackPage = () => (
  <Layout>
    <SEO
      title="Thesis | Give feedback"
      keywords={[]}
      description="Take the chance to provide feedback before unsubscribing from the Thesis mailing list"
    />
    <UnsubscribedFeedback />
  </Layout>
)

export default UnsubscribedFeedbackPage
