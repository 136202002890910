import React, { Component } from "react"
import "url"
import InnerForm from "./InnerForm"
// import ErrorMessage from "./ErrorMessage"
import Reveal from "react-reveal/Reveal"

import styles from "./styles.module.less"

const formsFeedback = "unsubscribedfeedback"

export default class UnsubscribedFeedbackPage extends Component {
  state = {
    message: "",
    errors: { message: "", form: "" },
    complete: false,
    failure: "",
  }
  handleChange = e => {
    const {
      target: { name, value },
    } = e
    this.setState({ [name]: value })
  }

  handleSubmit = e => {
    if (!this.isValid()) {
      e.preventDefault()
      return
    }
    // e.preventDefault()
    // if (!this.isValid()) return
    // const { message } = this.state
    // const body = new URLSearchParams()
    // body.append("form-name", formsFeedback)
    // body.append("message", message)

    // fetch("/unsubscribed-feedback/", {
    //   method: "POST",
    //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
    //   body,
    // })
    //   .then(() => {
    //     this.setState({ complete: true })
    //   })
    //   .catch(err => {
    //     this.setState({ complete: false, failure: err.message })
    //   })
  }

  isValid() {
    const { message } = this.state
    let isValid = true
    let errors = { message: "" }
    if (!message) {
      errors.message = "Enter a message"
      isValid = false
    }
    this.setState({ errors })
    return isValid
  }

  render() {
    return (
      <section className={styles.Contact}>
        <Reveal>
          <div className={styles.inner}>
            <div className={styles.title}>
              {!this.state.complete && <h1>You are unsubscribed.</h1>}
            </div>
            <h3>
              <i>Curious:</i> How could we be more relevant to you and your
              world?
            </h3>

            <div>
              <form
                name={formsFeedback}
                className={styles.contactForm}
                method="POST"
                data-netlify="true"
                onSubmit={this.handleSubmit}
                action="/unsubscribed-thankyou"
              >
                <input type="hidden" name="form-name" value={formsFeedback} />
                <InnerForm handleChange={this.handleChange} {...this.state} />
              </form>
            </div>
          </div>
        </Reveal>
      </section>
    )
  }
}
